// Riverside.js
import React from 'react';

const Riverside = () => {
  return (

   <>
   
   <div className='up-two'></div>
    <div className="about-event-container">
      <h2 className="title neonText head2">For Couples and Families: Riverside Hill Edge Party Zone and Camping</h2>
      
      <p>
        For couples and families seeking a more private and scenic experience, the Riverside Hill Edge Party Zone provides an idyllic escape amidst nature. This exclusive area is situated slightly away from the main stage, delivering a more tranquil setting with a breathtaking backdrop.
      </p>
      
      <hr className="divider" />
      
      <h3 className='head2'>Features and Highlights:</h3>
      <ul>
        <li>
          <strong className='head2'>Scenic Riverside Hill Location:</strong> Set on the edge of a serene riverside hill, this camping zone offers panoramic views, capturing the beauty of the surrounding landscape. With starlit skies above and the gentle flow of the river nearby, the ambiance creates a romantic and soothing environment perfect for couples and families alike.
        </li>
        <li>
          <strong className='head2'>Privacy and Peace:</strong> Slightly distanced from the main stage, this area provides a quieter alternative to the vibrant garden side, allowing you to enjoy the atmosphere without being in the center of the party. Couples can enjoy a cozy, intimate environment, while families can relax and feel secure.
        </li>
        <li>
          <strong className='head2'>Romantic and Family-Friendly Vibes:</strong> The Riverside Hill Edge Zone is thoughtfully designed to provide a mix of romance and family warmth. Couples can unwind with the scenic views and cozy tents, while families can enjoy quality time together in a relaxed and safe environment.
        </li>
        <li>
          <strong className='head2'>Access to Live Music and Activities:</strong> Although secluded, this zone still offers easy access to the live DJ, music, and activities. Couples and families can take part in salsa and ecstatic dance sessions or explore art shows before retreating to their peaceful camping zone.
        </li>
        <li>
          <strong className='head2'>Exclusive Atmosphere and Amenities:</strong> This area features premium camping amenities, ensuring comfort and convenience with easy access to restrooms, refreshment stalls, and security personnel nearby. Whether you’re relaxing after a dance or unwinding by the riverside, everything you need is within reach.
        </li>
      </ul>
      
      <p>
        The Riverside Hill Edge Party Zone is tailored for couples and families to enjoy a relaxed, scenic, and safe camping experience. Here, you can unwind, connect with nature, and make cherished memories in a beautiful, tranquil setting — all while staying close enough to the event action for an unforgettable night.
      </p>
    </div>
    
    </>


  );
};

export default Riverside;
