// CustomAlert.js
import React from 'react';
import './CustomAlert.css'; // Import CSS for the custom alert

const CustomAlert = ({ message, onClose }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert">
        <h2>Alert</h2>
        <p>{message}</p>
        <a href='https://insider.in/corporate-night-life-cocktail-party-dec21-2024/event' ><button className="alert-close-button" >Book Now!</button></a>
      </div>
    </div>
  );
};

export default CustomAlert;
