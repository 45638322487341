// PrivacyPolicy.js
import React from 'react';
import './common.css';

const PrivacyPolicy = () => {
  return (

    <>
    <div className='up-two'></div>
    <div className="common-container">
      <h2 className="Privacy-heading head2">Privacy Policy</h2>
      <ul className="Privacy-list">
        <li className="Privacy-item">
          <strong className='head2'>Data Collection:</strong> We collect personal data such as your name, email address, phone number, and payment details when you interact with our website or make bookings. This information is collected to process your bookings, payments, and inquiries.
        </li>
        <li className="Privacy-item">
          <strong className='head2'>Use of Personal Data:</strong> We use your data to process bookings and manage events, communicate with you about upcoming events and updates, and improve our services by understanding customer needs and preferences.
        </li>
        <li className="Privacy-item">
          <strong className='head2'>Data Sharing:</strong> Event Flow Asia does not sell, trade, or share your personal information with third parties, except for service providers who assist in managing the event (e.g., payment processors, vendors). These parties are bound by confidentiality agreements.
        </li>
        <li className="Privacy-item">
          <strong className='head2'>Security:</strong> We take the security of your data seriously and implement strict measures to protect your information from unauthorized access, misuse, or disclosure.
        </li>
        <li className="Privacy-item">
          <strong className='head2'>Cookies:</strong> Our website uses cookies to enhance your browsing experience. You can choose to accept or decline cookies via your browser settings.
        </li>
        <li className="Privacy-item">
          <strong className='head2'>Your Rights:</strong> You have the right to access, modify, or request deletion of your personal data. If you wish to exercise any of these rights, please contact us at info@eventflow.asia.
        </li>
        <li className="Privacy-item">
          <strong className='head2'>Changes to the Policy:</strong> Event Flow Asia reserves the right to update or modify this Privacy Policy at any time. All changes will be posted on our website, and your continued use of our services signifies your acceptance of the updated policy.
        </li>
      </ul>
    </div>
    
    </>
  );
};

export default PrivacyPolicy;
