// Games.js
import React from 'react';

const Games = () => {
  return (
    <>
    <div className='up-two'></div>
    
    <div className="about-event-container">
      <h2 className="title neonText head2">Corporate Nightlife Cocktail Party Games & Activities</h2>
      
      <div className="game-section">
        <h3 className='head2'>1. Beer Pong</h3>
        <p><strong className='head2'>Description:</strong> A classic party game that brings everyone together in friendly competition. Toss a ping-pong ball across a table, aiming to land it in one of the opposing team’s cups of beer.</p>
        <p><strong className='head2'>How to Play:</strong> Players take turns trying to land the ball in the other team’s cups. Each successful shot means the opposing team has to drink. The first team to eliminate all of the other team’s cups wins!</p>
      </div>
      
      <div className="game-section">
        <h3 className='head2'>2. Who Drinks Beer First?</h3>
        <p><strong className='head2'>Description:</strong> A fast-paced game for the bravest drinkers. In this race-against-the-clock, see who can finish their beer the fastest.</p>
        <p><strong className='head2'>How to Play:</strong> Line up with friends, grab your drinks, and be the first to finish. Whoever chugs their beer first is crowned the winner—cheers!</p>
      </div>

      <div className="game-section">
        <h3 className='head2'>3. Dapo Spinning Challenge</h3>
        <p><strong className='head2'>Description:</strong> A fun challenge with the traditional Indian game piece, Dapo. This game combines skill and patience as you try to keep the spin going.</p>
        <p><strong className='head2'>How to Play:</strong> Spin the Dapo as long as possible without letting it fall. The one with the longest spin wins the title of Dapo Master.</p>
      </div>

      <div className="game-section">
        <h3 className='head2'>4. Chess Club</h3>
        <p><strong className='head2'>Description:</strong> For those who enjoy a strategic challenge, the Chess Club offers a spot to exercise the mind. Take a break from the party vibe and engage in a classic game of chess with fellow enthusiasts.</p>
        <p><strong className='head2'>How to Play:</strong> Sit down at the chessboard, choose your pieces, and strategize your way to victory!</p>
      </div>

      <div className="game-section">
        <h3 className='head2'>5. Dare to Play</h3>
        <p><strong className='head2'>Description:</strong> This game adds a dash of daring fun. Participants will complete dares assigned by other players, adding excitement and unpredictability.</p>
        <p><strong className='head2'>How to Play:</strong> A participant picks a dare from a list given by a co-contestant. Whether it’s dancing solo, singing, or performing a funny act, it’s all about breaking out of your comfort zone and having fun.</p>
      </div>

      <div className="game-section">
        <h3 className='head2'>6. Couple Games for Couples</h3>
        <p><strong className='head2'>Description:</strong> Fun, bonding games just for couples to enjoy together and test their compatibility in playful ways.</p>
        <ul>
          <li>Mirror Dance: One partner leads while the other mirrors their dance moves.</li>
          <li>Know Your Partner: Answer questions about each other to see who knows the other best.</li>
          <li>Balloon Pop Challenge: Pop balloons between each other without using your hands.</li>
        </ul>
      </div>

      <div className="game-section">
        <h3 className='head2'>7. Salsa and Ecstatic Dance</h3>
        <p><strong className='head2'>Description:</strong> Get ready to hit the dance floor! Our instructors lead salsa sessions and an ecstatic dance experience that gets everyone moving in sync.</p>
        <p><strong className='head2'>How to Play:</strong> Join the salsa sessions and follow the instructor’s steps or let loose in the ecstatic dance circle, where you can groove freely to the beats. Feel the energy of the crowd as everyone moves together, creating an electric atmosphere.</p>
      </div>

      <div className="game-section">
        <h3 className='head2'>8. Corn Hole Game</h3>
        <p><strong className='head2'>Description:</strong> A relaxing, yet competitive game perfect for outdoor gatherings. Toss bean bags and aim for the hole in the board for points.</p>
        <p><strong className='head2'>How to Play:</strong> Players take turns throwing their bean bags toward the board, trying to get the bags in the hole for maximum points. The team with the highest score wins.</p>
      </div>

      <div className="game-section">
        <h3 className='head2'>9. Human Library</h3>
        <p><strong className='head2'>Description:</strong> An interactive experience where you “borrow” people instead of books, engaging in one-on-one conversations to gain unique insights and perspectives.</p>
        <p><strong className='head2'>Example:</strong> Chat with a “book” of your choice, whether it's a globetrotter with travel stories, a wellness expert with health tips, or a DJ with insights on music culture. Learn, ask questions, and enjoy meaningful connections with people from different backgrounds.</p>
      </div>

      <p>These games and activities offer something for everyone, from high-energy challenges to deep, meaningful interactions. Prepare for an unforgettable night filled with excitement, connection, and new experiences!</p>
    </div>
    
    </>
  );
};

export default Games;
