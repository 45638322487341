import React from "react";

const ContactUs = () => {
  return (
    <>

<div className="boxx-02" style={{paddingTop:'200px'}}>
          <h1 className="neonText head1">Contact Us</h1>
          
        </div>

        
      <div className="contact-us-container">
        

        <div className="contact-info">
        <p>
            Ready to plan your next event? Let us help you create an
            unforgettable experience.
          </p>
          <p>
            Email: <a href="mailto:eventflow.asia" className="head2">eventflowasia@gmail.com</a>
          </p>
          <p>
            Phone: <a href="tel:+919293225555" className="head2">+91 92 93 22 5555</a>
          </p>
        </div>
        <div className="contact-image">
          <img
            src="./images/contacttt.jpeg"
            alt="Contact Us"
            className="image"
          />
        </div>
      </div>
    </>
  );
};

export default ContactUs;
