// TermsAndConditions.js
import React from 'react';
import './common.css';

const TermsAndConditions = () => {
  return (
    <>
    <div className='up-two'></div>
    <div className="common-container">
      <h2 className="Terms-heading head2">Terms and Conditions</h2>
      <ol className="Terms-list">
        <li className="Terms-item">
          <strong className='head2'>Introduction:</strong> By accessing and using Event Flow Asia’s services, you agree to comply with and be bound by the following terms and conditions. These terms apply to all bookings and interactions with Event Flow Asia.
        </li>
        <li className="Terms-item">
          <strong className='head2'>Booking Confirmation:</strong> Bookings are only confirmed once full payment or an agreed deposit has been received. Event details, including dates, venues, and specific services, will be outlined in your booking confirmation.
        </li>
        <li className="Terms-item">
          <strong className='head2'>Payment Terms:</strong> Full payment is required before the event date, unless otherwise agreed. We accept payments via bank transfer, credit cards, and digital wallets. Payment terms for corporate or long-term contracts will be agreed upon separately.
        </li>
        <li className="Terms-item">
          <strong className='head2'>Event Modifications:</strong> Event Flow Asia reserves the right to modify event details or make necessary changes due to circumstances beyond our control, such as weather, venue issues, or force majeure.
        </li>
        <li className="Terms-item">
          <strong className='head2'>Client Responsibilities:</strong> Clients are responsible for providing accurate event details and ensuring that the venue meets the technical and logistic requirements. Any damages caused by the client or their guests may incur additional charges.
        </li>
        <li className="Terms-item">
          <strong className='head2'>Intellectual Property:</strong> All event concepts, logos, and designs created by Event Flow Asia remain our intellectual property unless otherwise stated. Unauthorized use of these materials is prohibited.
        </li>
        <li className="Terms-item">
          <strong className='head2'>Liability:</strong> Event Flow Asia is not liable for any indirect, incidental, or consequential damages arising from the use of our services, unless negligence or intentional misconduct is involved.
        </li>
        <li className="Terms-item">
          <strong className='head2'>Governing Law:</strong> These terms and conditions shall be governed by and construed in accordance with the laws of India. Any disputes arising shall be resolved through arbitration in Hyderabad, India.
        </li>
      </ol>
    </div>
    
    </>


  );
};

export default TermsAndConditions;
