// RefundPolicy.js
import React from 'react';
import './common.css';

const RefundPolicy = () => {
  return (
    <>
    
    <div className='up-two'></div>
    <div className="common-container">
      <h2 className="Refund-heading head2">Refund Policy</h2>
      <ul className="Refund-list">
      <h3 className='head2'>Event Cancellations</h3>
        
            <li><strong className='head2'>Client-Initiated Cancellation:</strong> If you cancel an event 30 days or more before the event date, you are eligible for a 75% refund of the total fee. Cancellations made between 15 to 30 days prior to the event will receive a 50% refund. Cancellations within 14 days or less from the event date are non-refundable.</li>
            <li><strong className='head2'>Event Flow Asia-Initiated Cancellation:</strong> In the rare event that we are forced to cancel an event due to unforeseen circumstances, we will offer a 100% refund or the option to reschedule without any additional fees.</li>
       
        <li className="Refund-item">
          <strong className='head2'>Refund Process:</strong> Refunds will be processed within 7-10 business days after the cancellation request has been approved. Refunds will be issued via the original payment method.
        </li>
        <li className="Refund-item">
          <strong className='head2'>Non-Refundable Items:</strong> Any deposits paid for venue reservations, catering, or other third-party services that are non-refundable by the vendor will not be eligible for refunds through Event Flow Asia.
        </li>
      </ul>
    </div>
    
    </>
  );
};

export default RefundPolicy;
