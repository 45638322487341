// FireCamp.js
import React from 'react';

const FireCamp = () => {
  return (

    <>
    
    <div className='up-two'></div>
    <div className="about-event-container">
      <h2 className="title neonText head2">Corporate Nightlife Cocktail Party Camping Experience</h2>
      
      <p>Our Corporate Nightlife Cocktail Party offers an unforgettable camping experience, with dedicated zones designed to cater to different groups and provide an ideal backdrop for your night.</p>
      
      <hr className="divider" />

      <h3>For Stags and Groups: Main Stage Camping Area – Garden Side</h3>
      <p>Experience the thrill of the party right from your tent in the Main Stage Camping Area located on the lively garden side. This zone is perfectly crafted for stags and groups who want to be at the center of the action, where the buzz of the event is just steps away.</p>
      
      <h4 className='head2'>Features and Highlights:</h4>
      <ul>
        <li><strong className='head2'>Prime Location Near the Main Stage:</strong> Settle in close to where all the energy is concentrated. This location ensures that the heart of the party — with live DJ sets, dance floors, and music pumping through the night — is right at your doorstep.</li>
        
        <li><strong className='head2'>Social Vibe and Group-Friendly:</strong> The garden side camping area is built for mingling and socializing. With ample space for groups, you’ll have plenty of opportunities to meet other party-goers and make new connections while enjoying the festival ambiance.</li>
        
        <li><strong className='head2'>Easy Access to Main Events and Activities:</strong> Located strategically for easy access, the garden-side camping keeps you within reach of exciting activities like salsa and ecstatic dance sessions, fire and art shows, and live games. Dive into the fun, then retreat to your tent whenever you need a break.</li>
        
        <li><strong className='head2'>Perfect for Nighttime Entertainment:</strong> With the vibrant lights of the main stage illuminating the area and the sounds of DJ beats echoing through the garden, this camping zone immerses you fully in the nightlife experience.</li>
      </ul>
      
      <p>The Main Stage Camping Area on the garden side provides stags and groups with an exclusive spot to stay connected to the lively essence of the party. Settle in, socialize, and make this unforgettable night truly yours!</p>
    </div>
    
    </>
  );
};

export default FireCamp;
