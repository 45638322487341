import React from "react";
import styles from "./HiringPartners.css";

const HiringPartners = () => {
  return (
    <div className="meet-our-partners-container">
      <div className="partners-heading-container">
        <p className="partners-heading">Meet our partners</p>
      </div>
      <div className="marquee-container">
        <div className="marquee">
          <div className="marquee-content">

            <div className="partner-logo-container">
              <img
                src="./images/paytm-insider.png"
                alt="insider"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Flow joy.jpg"
                alt="Flow-joy"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/sound-x.jpg"
                alt="sound-x"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/cornhole-logo.png"
                alt="cornhole-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="/images/bookmyshow.png"
                alt="bookmyshow logo"
                className="partner-logo"
              />
              
            </div>

            {/* ----------------------- */}

            <div className="partner-logo-container">
              <img
                src="./images/paytm-insider.png"
                alt="insider"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Flow joy.jpg"
                alt="Flow-joy"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/sound-x.jpg"
                alt="sound-x"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/cornhole-logo.png"
                alt="cornhole-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="/images/bookmyshow.png"
                alt="bookmyshow logo"
                className="partner-logo"
              />
              
            </div>

            {/* ----------------------- */}

            <div className="partner-logo-container">
              <img
                src="./images/paytm-insider.png"
                alt="insider"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Flow joy.jpg"
                alt="Flow-joy"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/sound-x.jpg"
                alt="sound-x"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/cornhole-logo.png"
                alt="cornhole-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="/images/bookmyshow.png"
                alt="bookmyshow logo"
                className="partner-logo"
              />
              
            </div>

            {/* ----------------------- */}

            <div className="partner-logo-container">
              <img
                src="./images/paytm-insider.png"
                alt="insider"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Flow joy.jpg"
                alt="Flow-joy"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/sound-x.jpg"
                alt="sound-x"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/cornhole-logo.png"
                alt="cornhole-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="/images/bookmyshow.png"
                alt="bookmyshow logo"
                className="partner-logo"
              />
              
            </div>

            {/* ----------------------- */}

            <div className="partner-logo-container">
              <img
                src="./images/paytm-insider.png"
                alt="insider"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Flow joy.jpg"
                alt="Flow-joy"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/sound-x.jpg"
                alt="sound-x"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/cornhole-logo.png"
                alt="cornhole-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="/images/bookmyshow.png"
                alt="bookmyshow logo"
                className="partner-logo"
              />
              
            </div>

            {/* ----------------------- */}

            <div className="partner-logo-container">
              <img
                src="./images/paytm-insider.png"
                alt="insider"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/vizag-sports-club-logo.jpeg"
                alt="vizag-sports-club-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/Flow joy.jpg"
                alt="Flow-joy"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/sound-x.jpg"
                alt="sound-x"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="./images/cornhole-logo.png"
                alt="cornhole-logo"
                className="partner-logo"
              />
              
            </div>

            <div className="partner-logo-container">
              <img
                src="/images/bookmyshow.png"
                alt="bookmyshow logo"
                className="partner-logo"
              />
              
            </div>

            {/* ----------------------- */}

            

            
            

            

            

          </div>
        </div>
      </div>
    </div>
  );
};

export default HiringPartners;
