// AboutUs.js
import React from 'react';
import './common.css';

const AboutUs = () => {
  return (
    <>
    <div className='up-two'></div>
    <div className="common-container">
      <h1 className="AboutUs-heading neonText head2">About Us</h1>
      <p className="AboutUs-paragraph">
        Event Flow Asia is the latest evolution of our journey in event management, bringing fresh energy and a renewed focus on delivering extraordinary experiences. Previously known as Unique Creations, we have been organizing unforgettable events since 2010, including international chess tournaments, school and college events, and wedding celebrations.
      </p>
      <p className="AboutUs-paragraph">
        Under the leadership of <span className='head2' style={{color:"#924834"}}>Karthik</span>, whose extensive experience and passion for excellence have been the driving force behind our success, we have now rebranded as Event Flow Asia to expand our reach through social media and digital platforms.
      </p>
      <p className="AboutUs-paragraph">
        Joining our journey is <span className='head2' style={{color:"#924834"}}>Harish</span>, our Technical and Designing Head, whose modern approach and expertise in digital strategies are helping us grow in the online space. With <span className='head2' style={{color:"#924834"}}>Harish’s</span> creativity and technical knowledge, we are ready to redefine how we connect with our clients and showcase our work.
      </p>
      <p className="AboutUs-paragraph">
        At Event Flow, we are committed to creating events that flow seamlessly, combining our years of experience with innovative ideas to deliver something truly special. From corporate night events and luxurious weddings to sports events, we take pride in making every moment unforgettable.
      </p>
      <p className="AboutUs-paragraph">
        We look forward to continuing our legacy with Event Flow Asia, bringing a perfect blend of tradition and modernity to every occasion.
      </p>
    </div>
    
    </>


  );
};

export default AboutUs;
