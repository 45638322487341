import React from "react";
import { Link } from "react-router-dom";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaWhatsappSquare,
  FaYoutubeSquare,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-content">
        <div className="footer-logo">
          <img
            src="./images/EVENTFLOWLOGO.png"
            alt="EvenFlow Logo"
            className="logo-image"
          />
          {/* <h2 className="logo-title">EvenFlow.Asia</h2> */}
        </div>

        <div className="footer-links">
          <Link to="/aboutus" className="footer-link  highlights-heading">
            About Us
          </Link>
          
          <Link to="/contactus" className="footer-link  highlights-heading">
            Contact
          </Link>

          <Link to="/privacypolicy" className="footer-link  highlights-heading">
          Privacy Policy
          </Link>
          
          <Link to="/refundpolicy" className="footer-link  highlights-heading">
          Refund Policy
          </Link>
          <Link to="/tandc" className="footer-link  highlights-heading">
          Terms And Conditions
          </Link>

        </div>

        {/* <div className="footer-social-media">
          <a
            href="https://www.facebook.com"
            className="social-icon"
            target="_blank"
            rel="noopener noreferrer">
            
            <FaFacebookSquare className="footer-icon" />
          </a>
          <a
            href="https://www.instagram.com"
            className="social-icon"
            target="_blank"
            rel="noopener noreferrer">

            <FaInstagramSquare className="footer-icon" />
          </a>
          <a
            href="https://www.twitter.com"
            className="social-icon"
            target="_blank"
            rel="noopener noreferrer">
            
            <FaWhatsappSquare className="footer-icon" />
          </a>
          <a
            href="https://www.linkedin.com"
            className="social-icon"
            target="_blank"
            rel="noopener noreferrer">
            
            <FaYoutubeSquare className="footer-icon" />
          </a>
        </div> */}

        <div className="footer-contact">
          <h2 style={{textAlign:'center',color:'#924834'}}>Contact Us:</h2>
          <p>Email: eventflowasia@gmail.com</p>
          <p>Phone: +91 92 93 22 5555</p>
        </div>
      </div>

      <div className="footer-bottom">
        <p style={{textAlign:'center'}} className="head2">&copy; 2024 EvenFlow.Asia. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
