import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CustomAlert from './CustomAlert';


const EntryPackages = () => {

    const [showAlert, setShowAlert] = useState(false);

  const handleBookingAlert = (e) => {
    e.preventDefault(); // Prevent default anchor behavior
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  return (

    <>
    
    <div className='up-two'></div>
    <div className="packages-container">
      <h1 className='head1'>Entry Packages</h1>
      
      <div className="package-box">
        <h2 className='head2'>Basic Stag Entry (Without Liquor & Kebabs): ₹2,500</h2>
        <p>Includes access to live games, after-party areas, and chill-out spots. Outside liquor not allowed, but available for purchase at the bar counter.</p>
        <Link to="/" className="book-button" onClick={handleBookingAlert}>BOOK NOW</Link>
      </div>

      <div className="package-box">
        <h2 className='head2'>Stag Entry (With Unlimited Cocktails & Kebabs): ₹4,999</h2>
        <p>Includes all activities, unlimited cocktails, and kebabs.</p>
        <Link to="/" className="book-button" onClick={handleBookingAlert}>BOOK NOW</Link>
      </div>

      <div className="package-box">
        <h2 className='head2'>Couple Entry (With Unlimited Cocktails & Kebabs): ₹6,999</h2>
        <p>Includes all activities, unlimited cocktails, and kebabs. Access to the exclusive garden-side party.</p>
        <Link to="/" className="book-button" onClick={handleBookingAlert}>BOOK NOW</Link>
      </div>

      <h1 className='head1'>Group Packages</h1>
      
      <div className="package-box">
        <h2 className='head2'>Group of 5: ₹19,999</h2>
        <Link to="/" className="book-button" onClick={handleBookingAlert}>BOOK NOW</Link>
      </div>

      <div className="package-box">
        <h2 className='head2'>Group of 7: ₹29,999</h2>
        <Link to="/" className="book-button" onClick={handleBookingAlert}>BOOK NOW</Link>
      </div>

      <div className="package-box">
        <h2 className='head2'>Group of 10: ₹35,999</h2>
        <Link to="/" className="book-button" onClick={handleBookingAlert}>BOOK NOW</Link>
      </div>

      <h1 className='head1'>Early Bird Discounts</h1>
      <div className="early-bird">
        <h2 className='head2'>Phase 1: First 200 Entries</h2>
        <p>Stag Entry (With Liquor & Kebabs): ₹3,999</p>
        <p>Couple Entry: ₹5,999</p>
        <p>Group of 5: ₹17,999</p>
      </div>
      
      <div className="early-bird">
        <h2 className='head2'>Phase 2: Next 300 Entries (201-500 Entries)</h2>
        <p>Stag Entry (With Liquor & Kebabs): ₹4,499</p>
        <p>Couple Entry: ₹6,499</p>
        <p>Group of 5: ₹18,999</p>
      </div>
      
      <div className="early-bird">
        <h2 className='head2'>Phase 3: Entries Beyond 500</h2>
        <p>Stag Entry (With Liquor & Kebabs): ₹4,999</p>
        <p>Couple Entry: ₹6,999</p>
        <p>Group of 5: ₹19,999</p>
      </div>

      <h1 className='head1'>Book Your Tickets</h1>
      <h2 style={{textAlign:'center'}} className='head1'>Bookings Open Now</h2>
      <div className="booking-platforms">
        <a href="https://in.bookmyshow.com/events/corporate-night-life-cocktail-party/ET00419740" target="_blank" rel="noopener noreferrer">
          <img src="/images/bookmyshow.png" alt="BookMyShow" className="platform-image" />
        </a>
        <a href="https://insider.in/corporate-night-life-cocktail-party-dec21-2024/event" target="_blank" rel="noopener noreferrer">
          <img src="/images/paytm-insider.png" alt="Paytm Insider" className="platform-image" />
        </a>
        {/* <a href="https://idamos.com" target="_blank" rel="noopener noreferrer">
          <img src="/images/idamos.png" onClick={handleBookingAlert} alt="idamos" className="platform-image" />
        </a>
        <a href="https://madvibes.com" target="_blank" rel="noopener noreferrer">
          <img src="/images/madvibes.png" onClick={handleBookingAlert} alt="Madvibes" className="platform-image" />
        </a>
        <a href="https://partyhunt.com" target="_blank" rel="noopener noreferrer">
          <img src="/images/partyhunt.png" onClick={handleBookingAlert} alt="Partyhunt" className="platform-image" />
        </a> */}
      </div>
    </div>
    {showAlert && <CustomAlert message="Now tickets available on Paytm Insider." onClose={handleCloseAlert} />}
    </>


  );
};

export default EntryPackages;
