import React from 'react';
import { Link } from 'react-router-dom';
import './FloatingButton.css'; // CSS file for styling

const FloatingButton = () => {
  return (
    <div className="floating-button">
      <Link to="https://lifehisto.com/" className="styled-link">
        <button className="book-button">
          <img
            src="./images/booknow.png" // Replace with your icon URL
            alt="Book Icon"
            className="button-icon"
          />
          BOOK NOW
        </button>
      </Link>
    </div>
  );
};

export default FloatingButton;
