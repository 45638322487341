// Schedule.js
import React from 'react';

const Schedule = () => {
  return (
    <>
      <div className='up-two'></div>
      <div className="schedule-container">
        <h1 className="title neonText head2">Corporate Night Life Cocktail Party</h1>
        <p className="venue-details">
          Venue: Harivillu Resorts <br />
          Date: December 21st, 2024 <br />
          Check-In: 3:00 PM (Dec 21st) | Check-Out: 11:00 AM (Dec 22nd)
        </p>

        <section className="event-schedule">
          <h2 className="subtitle head2">Event Schedule</h2>
          <ul className="schedule-list">
            <li><span className="time">3:00 PM - 8:00 PM:</span> Live Games</li>
            <li><span className="time">8:00 PM - 12:00 AM:</span> Unlimited Cocktails, Kebabs & Live DJ Bollywood Mix and Techno Music</li>
            <li><span className="time">After Midnight:</span> Separate Chill-out and Party Areas for Different Groups</li>
          </ul>
        </section>

        <section className="live-games">
          <h2 className="subtitle head2">Live Games (3:00 PM - 8:00 PM)</h2>
          <ul className="games-list">
            <li><strong className='head2'>Beer Pong:</strong> Compete with friends to sink the ball into the cup and make the other team drink first.</li>
            <li><strong className='head2'>Who Drinks Beer First?</strong> A race against time! Whoever finishes their beer the fastest wins.</li>
            <li><strong className='head2'>Corn Hole India Game Challenge:</strong> Toss the bean bags into the hole in this Indian twist on the classic Corn Hole game.</li>
            <li><strong className='head2'>Dapo Long Spin Challenge:</strong> Spin the Dapo for as long as you can — the longest spinner takes home the prize!</li>
            <li><strong className='head2'>Live PUBG Gaming Challenge:</strong> Team up or go solo in this live PUBG gaming showdown to prove who’s the ultimate champion.</li>
          </ul>
        </section>

        <section className="menu">
          <h2 className="subtitle head2">Cocktail & Kebabs Menu (8:00 PM - 12:00 AM)</h2>
          <h3 className='head2'>Cocktail Menu</h3>
          <ul className="cocktail-list">
            <li><strong className='head2'>Virgin Sports:</strong> A refreshing mix of vodka, white rum, orange slices, soda, and sugar syrup.</li>
            <li><strong className='head2'>CocoGin:</strong> A tropical mix of gin, coconut water, and honey for a sweet and smooth drink.</li>
            <li><strong className='head2'>Fire Shots:</strong> Turn up the heat with these fiery, flaming shots.</li>
            <li><strong className='head2'>Whisky Cocktails:</strong> Whisky Sour and Whisky Highball — classic whisky cocktails with a twist.</li>
            <li><strong className='head2'>Brandy with Warm Water:</strong> A classic warm drink for brandy lovers.</li>
          </ul>

          <h3 className='head2'>Kebabs Menu</h3>
          <ul className="kebab-list">
            <li>Tandoori Chicken Kebabs</li>
            <li>Pineapple Kebabs</li>
            <li>Aloo Kebabs</li>
            <li>Tandoori Prawns</li>
            <li>Fish Tikka</li>
          </ul>
        </section>

        <section className="dinner-breakfast">
          <h2 className="subtitle head2">Dinner Menu for All Participants (Served at 9:00 PM)</h2>
          <ul className="dinner-list">
            <li>Bagara Rice</li>
            <li>Chicken Curry</li>
            <li>Paneer Curry</li>
            <li>White Rice</li>
            <li>Sambar</li>
            <li>Curd</li>
            <li>Pickle</li>
            <li>Ice Cream</li>
          </ul>

          <h2 className="subtitle head2">Morning Breakfast (Served at 8:00 AM)</h2>
          <ul className="breakfast-list">
            <li>Tea & Coffee</li>
            <li>Poori with Aloo Curry</li>
            <li>Upma with Chutney</li>
            <li>Fresh Fruit Juice</li>
            <li>Bread & Jam</li>
            <li>Milk & Cornflakes</li>
          </ul>
        </section>

        <section className="after-midnight">
          <h2 className="subtitle head2">After Midnight: Separate Chill-Out and Party Areas</h2>
          <ul className="chill-out-list">
            <li><strong className='head2'>Stags at the Camping Side:</strong> Enjoy games, music, and a stag experience under the stars.</li>
            <li><strong className='head2'>Groups at the Riverside:</strong> Chill out by the river with live music, space to dance, and vibrant vibes.</li>
            <li><strong className='head2'>Couples at the Garden Side:</strong> A romantic night with a live DJ and a private, intimate atmosphere.</li>
          </ul>

          <p>We also have several chill-out spots across the venue for relaxing and enjoying the night.</p>
        </section>

        

        <section className="accommodation">
          <h2 className="subtitle head2">Accommodation Options</h2>
          <p>Stay in luxurious camping tents or exclusive suites, tailored for couples, families, and groups.</p>
          <p className="booking-info">
            Book Now for Early Bird Pricing! Limited entries available. For more details and bookings, visit: <a className='head2' href="https://www.eventflow.asia" target="_blank" rel="noopener noreferrer">www.eventflow.asia</a> or WhatsApp us at <strong className='head2'>95533 21111.</strong>
          </p>
        </section>

        <p className="checkout-time">Checkout Time: 11:00 AM (December 22nd, 2024)</p>
      </div>
    </>
  );
};

export default Schedule;
