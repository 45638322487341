// Stay.js
import React from 'react';

const Stay = () => {
  return (
    <>
      <div className='up-two'></div>
      <div className="stay-container">
        <h2 className="title neonText head2">Camping & Stay</h2>
        <p>Camping at Eventflow Asia’s Corporate Nightlife Party</p>
        <p>Get ready for an immersive camping experience with tents set against the vibrant party backdrop, allowing you to stay close to the action while enjoying a comfortable retreat.</p>
        
        <h3>Tent Options:</h3>
        <div className="stay-options">
          <div className="stay-card">
            <h3 className='head2'>5-Person Group Sharing Tent</h3>
            <p>Ideal for groups, this spacious tent accommodates five people comfortably, allowing your crew to stay together throughout the night.</p>
          </div>
          <div className="stay-card">
            <h3 className='head2'>2-Person Sharing Tent</h3>
            <p>Perfect for couples or friends, this cozy setup ensures privacy and a relaxed atmosphere for two.</p>
          </div>
          <div className="stay-card">
            <h3 className='head2'>Family Tent (3 to 4 People)</h3>
            <p>Tailored for families or small groups, this tent offers ample space, comfort, and the convenience of staying together.</p>
          </div>
        </div>
        
        <h3>Setup Details:</h3>
        <p>All tents are located just behind the main party area, so you’re never too far from the live DJ, games, and entertainment. This unique setup keeps you immersed in the lively atmosphere while providing a peaceful retreat when you need it.</p>
        <h3>Note:</h3>
        <p>luxury and suit room are available on charge bases</p>
      </div>
    </>
  );
};

export default Stay;
