import React from 'react';
import { Link } from 'react-router-dom';

const HighlightBoxes = () => {
  return (
    <div className="highlights-container">
      <Link to="/fire-camp" className="highlights-box">
        <img 
          src="./images/campside.jpg" 
          alt="Fire Camping Party" 
          className="highlights-image" 
        />
        <h2 className="highlights-heading neonText head1">Fire Camping Party</h2>
      </Link>

      <Link to="/river-side" className="highlights-box">
        <img 
          src="./images/poolside.webp" 
          alt="Riverside Party" 
          className="highlights-image" 
        />
        <h2 className="highlights-heading neonText head1">Riverside Party</h2>
      </Link>

      <Link to="/games" className="highlights-box">
        <img 
          src="./images/games.webp" 
          alt="games Party" 
          className="highlights-image" 
        />
        <h2 className="highlights-heading neonText head1">Games</h2>
      </Link>

      <Link to="/more" className="highlights-box">
        <img 
          src="./images/more3.png" 
          alt="About Event" 
          className="highlights-image" 
        />
        <h2 className="highlights-heading neonText head1">About Event</h2>
      </Link>
    </div>
  );
};

export default HighlightBoxes;
